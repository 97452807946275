<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <SpotEdit :spotId="spotId" @updateSpotListing="populateTableFromApi" v-if="spotId" />
        <v-card v-else>
          <v-card-title>Spot Admin</v-card-title>
          <v-card-actions>
            <v-container cols="12">
              <v-row justify="space-between" no-gutters>
                <v-col>
                  <v-btn x-small :to="{ name: 'Spot Editor', params: { spotId: 'create' } }">
                    Add New Spot
                  </v-btn>
                </v-col>
                <v-col cols="2">
                  <v-switch v-model="hideDisabled" :label="`Hide Disabled`" class="mt-0"></v-switch>
                </v-col>
                <v-col cols="auto">
                  <v-switch v-model="groupSpots" :label="`Group Spots`" class="mt-0"></v-switch>
                </v-col>
              </v-row>
              <v-row justify="space-between" no-gutters align="end">
                <v-col cols="4">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" v-if="groupSpots">
                  <v-btn x-small @click="collapseGroups">
                    Collapse All
                  </v-btn>
                  <v-btn x-small @click="expandGroups">
                    Expand All
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
          <v-data-table
            :headers="headers"
            :items="tableItems"
            :loading="loading"
            :group-by="groupSpots ? groupBy : null"
            :group-desc="groupSpots ? true : null"
            @click:row="goToSpotEdit"
            :search="search"
            :sort-by="[`active`, `spot`]"
            :sort-desc="[true, false]"
            multi-sort
            must-sort
            class="elevation-2"
            disable-pagination
            hide-default-footer
            id="spotTable"
            ref="table"
          >
            <!-- Example of additional column to data-table
                 despite there being no cooresponding DB table column -->
            <!-- <template #item.action="{item}">
              <v-btn color="rgba(255,0,0,.7)" @click.stop="deleteSpot(item)">
                <v-icon color="white">mdi-delete</v-icon>
              </v-btn>
            </template> -->
            <template #item.description="{item}">
              <td v-html="item.description"></td>
            </template>
            <template #item.active="{item}">
                <v-checkbox
                  v-model="item.active"
                  :true-value="`1`"
                  :false-value="`0`"
                  class="ml-1"
                  disabled
                ></v-checkbox>
            </template>
            <template #group.header="{group, isOpen}">
              <th :colspan="headers.length" @click="toggleGroup(group)" class="group-header">
                <v-icon :ref="group || `Not Grouped`" :data-open="isOpen">
                  {{ isOpen ? "mdi-chevron-down" : "mdi-chevron-right" }}
                </v-icon>
                {{ group || "Not Grouped" }}
              </th>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SpotEdit from "@/components/medusa/SpotEdit";
import { mapState, mapMutations } from "vuex";

export default {
  components: {
    SpotEdit
  },
  data() {
    return {
      tableItems: [],
      loading: true,
      groupBy: "list_group",
      search: "",
      headers: [
        { text: "Spot Name", value: "spot", divider: true },
        { text: "Description", value: "description", divider: true, sortable: false },
        { text: "Active", value: "active", divider: true, width: "70px", filter: (value) => this.disabledFilter(value) }
        /* Example of additional column to data-table despite there being no cooresponding DB table column */
        // { text: "Action", value: "action", divider: true, sortable: false }
      ]
    };
  },
  mounted() {
    if (!this.spotId)
      this.updateOpenedSpotGroups();
  },
  watch: {
    spotId(spotId) {
      // If there is no new value for spotId, means we're on listing view and should update which spot groups are expanded
      if (!spotId) {
        // Must wait for next tick so listing view is rendered
        this.$nextTick(() => {
          this.updateOpenedSpotGroups();
        });
      }
    }
  },
  computed: {
    ...mapState({
      isEditing: (state) => state.backfillPools.isEditing,
      openedSpotGroups: (state) => state.spot.openedSpotGroups
    }),
    // Setters won't work inside mapState
    groupSpots: {
      get() {
        return this.$store.state.spot.groupSpots;
      },
      set(value) {
        this.setGroupSpots(value);
        // quasi-private object that holds table group expanded/collapsed data
        let openGroupCache = this.$refs.table.$vnode.componentInstance.openCache;
        this.$store.dispatch("spot/updateOpenedSpotGroups", openGroupCache);
      }
    },
    hideDisabled: {
      get() {
        return this.$store.state.spot.hideDisabled;
      },
      set(value) {
        this.setHideDisabled(value);
      }
    },
    spotId() {
      return this.$route.params.spotId;
    }
  },
  // Lifecycle hook
  created() {
    // Populate listing on creation
    this.populateTableFromApi();
    this.setIsEditing(false);
  },
  methods: {
    ...mapMutations({
      setGroupSpots: "spot/setGroupSpots",
      setHideDisabled: "spot/setHideDisabled",
      setIsEditing: "backfillPools/setIsEditing"
    }),
    collapseGroups() {
      // quasi-private object that holds table group expanded/collapsed data
      let openGroupCache = this.$refs.table.$vnode.componentInstance.openCache;
      let keys = Object.keys(openGroupCache);
      keys.forEach(x => {
        openGroupCache[x] = false;
      });
      this.$store.dispatch("spot/updateOpenedSpotGroups", openGroupCache);
    },
    disabledFilter(value) {
      return this.hideDisabled ? value == "1" : true;
    },
    expandGroups() {
      // quasi-private object that holds table group expanded/collapsed data
      let openGroupCache = this.$refs.table.$vnode.componentInstance.openCache;
      let keys = Object.keys(openGroupCache);
      keys.forEach(x => {
        openGroupCache[x] = true;
      });
      this.$store.dispatch("spot/updateOpenedSpotGroups", openGroupCache);
    },
    goToSpotEdit(item) {
      this.$router.push({
        params: {
          spotId: item.spot_id
        }
      });
    },
    populateTableFromApi() {
      this.loading = true;
      let isAdmin = this.$store.state.auth.user.rolename == "admin";
      this.$axios
        .get("/medusa/spot/list", {
          params: {
            isAdmin: isAdmin
          }
        })
        .then((res) => {
          this.loading = false;
          this.tableItems = res.data;
        });
    },
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = "";
    },
    updateOpenedSpotGroups() {
      // update quasi-private object that holds table group expanded/collapsed data
      this.$refs.table.$vnode.componentInstance.openCache = {...this.openedSpotGroups};
    },
    toggleGroup(group) {
      // quasi-private object that holds table group expanded/collapsed data
      let openGroupCache = this.$refs.table.$vnode.componentInstance.openCache;
      openGroupCache[group] = !openGroupCache[group];
      this.$store.dispatch("spot/updateOpenedSpotGroup", {
        group: group, 
        value: openGroupCache[group]
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventNav);
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm("Leave without saving changes?")) {
        return;
      }
    }
    next();
  },
  beforeRouteUpdate(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm("Leave without saving changes?")) {
        return;
      }
    }
    next();
  },
};
</script>

<style scoped lang="scss">
/* Fix v-card-action padding to match v-card-title */
.v-card__actions {
  padding: 16px;
}

::v-deep tbody tr {
  cursor: pointer;
}

.group-header {
  text-transform: capitalize;
}

::v-deep #spotTable {
  tbody  tr {
    &:nth-of-type(even) {
      background-color: rgba(150, 150, 150, 0.2);
    }
    &:hover {
      background: lighten(gray, 25%);
    }
  }
}
</style>
