<template>
  <v-dialog persistent v-model="$store.state.spot.previewParamDialog" max-width="1000px">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" :disabled="spotLoading" color="primary">
        Preview Spot
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ previewType | capitalize }} Url Builder</v-card-title>
      <v-container>
        <v-row>
          <v-col>
            <label><b>Type: </b></label>
            <v-btn-toggle
              borderless
              dense
              mandatory
              tile
              v-model="previewType"
              color="blue accent-4"
              title="Type"
            >
              <!-- If spot already has type set, disable all types but that one -->
              <v-btn x-small value="live">Live</v-btn>
              <v-btn x-small value="preview">Preview</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col>
            <label><b>Format: </b></label>
            <v-btn-toggle
              borderless
              dense
              mandatory
              tile
              v-model="format"
              color="blue accent-4"
              title="Format"
            >
              <!-- If spot already has type set, disable all types but that one -->
              <v-btn x-small value="html">Html</v-btn>
              <v-btn x-small value="text">Text</v-btn>
              <v-btn x-small value="xml">Xml</v-btn>
              <v-btn x-small value="json">Json</v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-1">
            <v-btn @click="addParameter" color="blue darken-1" tile small dark>
              Add Parameter
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="11" class="pt-0">
            <v-list
              ref="paramList"
              class="scrolling-list pt-0"
              elevation="2"
              color="grey lighten-5"
            >
              <v-list-item v-for="(selectedParameter, index) in selectedParameters" :key="index">
                <v-container class="pa-0">
                  <v-row class="pt-2">
                    <v-col class="py-0 pl-0 pr-1" cols="3">
                      <v-select
                        :items="filteredParameterSelectList"
                        v-model="selectedParameter.parameter"
                        :loading="loading"
                        @change="setDefaultValue(index, selectedParameter)"
                        label="Parameter"
                        hide-selected
                        hide-details
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col class="py-0 pl-0 pr-1">
                      <v-text-field
                        label="Value"
                        v-model="selectedParameter.value"
                        :disabled="!selectedParameter.parameter"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col class="pa-0" cols="auto">
                      <v-btn color="rgba(255,0,0,.7)" @click.stop="removeParameter(index)" icon>
                        <v-icon color="black">mdi-close-circle-outline</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="closeDialog('close')">Close</v-btn>
        <v-btn @click="openPreview" color="success">Preview</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data: () => ({
    loading: false,
    parameterSelectList: [],
    // essentially an associative array with parameter name as key and default paramater value as value
    parameterDefaultValueMap: {},
    parameters: [],
  }),
  // Lifecycle hook
  mounted() {
    this.populateParameterList();
  },
  watch: {
    // Deep watch activeFilters and explicitly set the activeFilters object in vuex so that it's picked up by vuex-persistedstate
    selectedParameters: {
      handler: function(newVal) {
        this.setSelectedParameters(newVal);
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      // Sets disabled property if parameter has already been selected
      filteredParameterSelectList() {
        return this.parameterSelectList.map((listItem) => ({
          text: listItem["text"],
          value: listItem["value"],
          disabled: this.selectedParameterNames.includes(listItem["text"])
        }));
      },
      previewUrl() {
        return `${this.$store.getters["auth/getApiHost"]["medusa_api_ext"]}/spot/preview`;
      },
      previewLiveUrl() {
        return `${this.$store.getters["auth/getApiHost"]["medusa_api_ext"]}/medusa/spot/${this.spot.spot}.${this.format}`;
      },
      selectedParameterNames() {
        return this.selectedParameters.map((parameter) => parameter.parameter);
      },
      selectedParameters(state) {
        return state.spotPreview.selectedParameters;
      },
      spot(state) {
        return state.spot.spot;
      },
      spotLoading(state) {
        return state.spot.spotLoading;
      }
    }),
    previewType: {
      get() {
        return this.$store.state.spotPreview.previewType;
      },
      set(value) {
        this.setPreviewType(value);
      }
    },
    format: {
      get() {
        return this.$store.state.spotPreview.format;
      },
      set(value) {
        this.setFormat(value);
      }
    }
  },
  methods: {
    ...mapMutations({
      setFormat: "spotPreview/setFormat",
      setPreviewParamDialog: "spot/setPreviewParamDialog",
      setPreviewType: "spotPreview/setPreviewType",
      setSelectedParameters: "spotPreview/setSelectedParameters"
    }),
    addParameter() {
      // Promise is necessary to ensure the div is scrolled to the bottom AFTER adding the new parameter
      new Promise((resolve) => {
        this.selectedParameters.push({ parameter: "", value: "" });
        resolve(true);
      }).then(() => {
        this.scrollToNewParameter();
      });
    },
    appendParameters(baseUrl) {
      let url = new URL(baseUrl);
      this.selectedParameters.forEach((param) => {
        if (param.parameter) url.searchParams.set(param.parameter, param.value);
      });
      return url;
    },
    closeDialog() {
      this.setPreviewParamDialog(false);
    },
    openPreview() {
      if (this.previewType == "preview") {
        this.previewCurrent();
      } else {
        this.openNewTab();
      }
    },
    // Opens url in new tab (for get requests)
    openNewTab() {
      let url = this.appendParameters(this.previewLiveUrl);
      window.open(url, "_blank");
    },
    // Opens url with post data in new tab
    // Works by programmatically builds, appends, submits, and then removes a hidden form.
    openNewTabWithPost(url, data) {
      var form = document.createElement("form");
      form.target = "_blank";
      form.method = "POST";
      form.action = url;
      form.style.display = "none";
      for (var key in data) {
        var input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = data[key];
        form.appendChild(input);
      }
      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    },
    populateParameterList() {
      this.loading = true;
      this.axios
        .get("medusa/spot/spotParameterList")
        .then((res) => {
          this.parameterSelectList = res.data.map((e) => ({
            text: e["param_name"],
            value: e["param_name"],
            disabled: false
          }));
          res.data.map((param) => (this.parameterDefaultValueMap[param.param_name] = param.value));
          this.parameters = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeParameter(index) {
      this.selectedParameters.splice(index, 1);
    },
    // Scrolls the param list element to the bottom, revealing the newly added parameter
    scrollToNewParameter() {
      let paramList = this.$refs.paramList.$el;
      paramList.scrollTop = paramList.scrollHeight;
    },
    setDefaultValue(index, selectedParameter) {
      selectedParameter.value = this.parameterDefaultValueMap[selectedParameter.parameter];
    },
    previewCurrent() {
      let data = {};
      data["spot"] = this.spot.spot;
      data["format"] = this.format;
      // If spot is using a template, incorporate the spot template's values to preview velocity template
      if (this.spot.template_spot_id) {
        let formatVelocityTemplate = "";
        data["template"] = this.spot.model || this.spot.templateModel;
        switch (this.format) {
          case "html":
            formatVelocityTemplate = this.spot.html || this.spot.templateHtml;
            break;
          case "json":
            formatVelocityTemplate = this.spot.json || this.spot.templateJson;
            break;
          case "xml":
            formatVelocityTemplate = this.spot.xml || this.spot.templateXml;
            break;
          case "text":
            formatVelocityTemplate = this.spot.text || this.spot.templateText;
            break;
        }
        data["template"] += " " + formatVelocityTemplate;
      } else {
        data["template"] = this.spot.model + " " + this.spot[this.format];
      }
      let url = this.appendParameters(this.previewUrl);
      this.openNewTabWithPost(url.toString(), data);
    }
  },
  filters: {
    // Capitalizes first letter
    capitalize: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
};
</script>

<style scoped>
.group-header {
  text-transform: capitalize;
}

.scrolling-list {
  height: 255px;
  max-height: 255px;
  overflow-y: scroll;
}

::v-deep .theme--light.v-select .v-select__selection--disabled {
  color: rgba(0, 0, 0, 1);
}
</style>
