<template>
  <div style="display: contents">
    <v-card v-if="spotId" outlined :loading="loading">
      <v-card-text class="body-2">
        <div>
          Last viewed on
          <span class="font-weight-medium">{{ info.lastView }}</span> with
          <span class="font-weight-medium">{{ info.impressions }}</span> impressions. Last clicked
          on <span class="font-weight-medium">{{ info.lastClick }}</span> with
          <span class="font-weight-medium">{{ info.clicks }}</span> clicks.
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn x-small icon v-on="on" @click="load(1)">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </template>
            <span>Get real time data</span>
          </v-tooltip>
          <v-btn
            class="indigo--text text--darken-4"
            plain
            small
            :href="perfReportUrl"
            target="_blank"
            >View Performance Report<v-icon x-small>mdi-google-analytics</v-icon></v-btn
          >
        </div>
        <div>
          Created on <span class="font-weight-medium">{{ info.create_date }} </span>. Last updated
          on <span class="font-weight-medium">{{ info.last_modified }} </span> by
          <span class="font-weight-medium">{{ info.modified_by }}.</span>
          <v-btn class="indigo--text text--darken-4" plain small @click="openLog" target="_blank"
            >View change history<v-icon x-small>mdi-table-clock</v-icon></v-btn
          >
        </div>
      </v-card-text>
    </v-card>
    <SpotEditHistoryDialog v-model="historyDialog" :spot="spot" />
  </div>
</template>

<script>
import SpotEditHistoryDialog from "@/components/medusa/SpotEditHistoryDialog";

export default {
  components: {
    SpotEditHistoryDialog
  },
  props: ["spot"],
  computed: {
    spotId() {
      return this.spot.spot_id;
    },
    perfReportUrl() {
      return `https://datastudio.google.com/u/0/reporting/897635bb-033e-4fd7-9171-c6458e937587/page/rWDQB?params=%7B%22df18%22%3A%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${this.spot.spot}%22%7D`;
    }
  },
  data() {
    return {
      historyDialog: false,
      loading: false,
      info: {
        lastView: "-",
        impressions: "-",
        lastClick: "-",
        clicks: "-",
        create_date: "-",
        last_modified: "-",
        modified_by: "-"
      }
    };
  },
  watch: {
    spotId: {
      immediate: true,
      handler(v) {
        if (v) this.load();
      }
    }
  },
  methods: {
    openLog() {
      this.historyDialog = true;
    },
    load(realtime = 0) {
      this.loading = true;
      this.$axios
        .get("/medusa/spot/getActivity", {
          params: {
            spotId: this.spotId,
            realtime: realtime
          }
        })
        .then((res) => {
          this.info = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
