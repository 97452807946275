<template>
  <v-card :loading="pageLoading">
    <v-card-title>
      <template v-if="!spotExists">
        Create Spot
      </template>
      <template v-else>
        Edit Spot
      </template>
      <v-spacer></v-spacer>
      <v-btn color="primary" :to="{ name: 'Spot Editor' }" v-if="isAdmin">
        Back to list
      </v-btn>
    </v-card-title>
    <v-container fluid>
      <v-form ref="form" v-model="spotFormIsValid">
        <v-row>
          <v-col cols="6">
            <v-container fluid class="pa-0">
              <v-row>
                <v-col cols="9">
                  <v-text-field
                    label="Spot Name"
                    v-model="spot.spot"
                    :rules="[rules.required, rules.maxChars(spot.spot, 30), rules.noSpaces]"
                    @input="validateUniqueSpotName"
                    :error-messages="spotNameErrors"
                    :loading="spotNameLoading"
                    counter
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-switch
                    label="Active"
                    v-model="spot.active"
                    :true-value="`1`"
                    :false-value="`0`"
                    :messages="spot.active == 1 ? `Spot is active` : `Spot is disabled`"
                    v-if="spot.type == `spot`"
                    @change="setIsEditing(true)"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-container>

            <label v-if="isAdmin"><b>Spot Type: </b></label>
            <v-btn-toggle
              class="pb-6"
              borderless
              dense
              mandatory
              v-model="spot.type"
              color="blue accent-4"
              v-if="isAdmin"
              @change="setIsEditing(true)"
            >
              <!-- If spot already has type set, disable all types but that one -->
              <v-btn value="template" :disabled="spotExists && initialSpotType != 'template'"
                >Template</v-btn
              >
              <v-btn
                value="template-default"
                v-if="spotExists"
                :disabled="!spotExists || (spotExists && initialSpotType != 'template-default')"
                >Default Template</v-btn
              >
              <v-btn value="system" :disabled="spotExists && initialSpotType != 'system'"
                >System</v-btn
              >
              <v-btn value="spot" :disabled="spotExists && initialSpotType != 'spot'">Spot</v-btn>
            </v-btn-toggle>
            <v-textarea
              label="Description"
              v-model="spot.description"
              :rules="[rules.maxChars(spot.description, 355)]"
              outlined
              auto-grow
              rows="2"
              counter
              @input="setIsEditing(true)"
            ></v-textarea>
            <v-row>
              <v-col>
                <v-select
                  label="Spot Group"
                  v-model="spot.spot_group_id"
                  :items="spot_groups"
                  clearable
                  v-if="spot.type == `spot`"
                  @change="setIsEditing(true)"
                ></v-select>
              </v-col>
              <v-col>
                <!-- Hide unless spot is of type 'spot' -->
                <v-select
                  label="Spot Template"
                  v-model="spot.template_spot_id"
                  :items="template_spots"
                  clearable
                  v-if="spot.type == `spot`"
                  @change="setIsEditing(true)"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-switch
              label="Enable Backfill"
              v-model="spot.enable_backfill"
              :true-value="`1`"
              :false-value="`0`"
              :messages="spot.enable_backfill == 1 ? `` : `Backfill is disabled`"
              v-if="spot.type == `spot`"
              @change="setIsEditing(true)"
            ></v-switch>
            <app-backfill-pools-table
              :spotId="spotId"
              v-if="spot.type == `spot` && spot.enable_backfill == `1`"
            ></app-backfill-pools-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <!-- Hide div if user is not an admin -->
            <template v-if="isAdmin">
              <template v-if="!spot.template_spot_id">
                <label><b>Model: </b></label>
                <prism-editor
                  class="mb-4"
                  :style="{ height: '300px' }"
                  @input="setIsEditing(true)"
                  v-model="spot.model"
                  lang="velocity"
                ></prism-editor>
              </template>

              <v-btn-toggle
                v-model="displayedFormat"
                @change="onEditor('blur', displayedFormat)"
                color="blue accent-4"
                mandatory
              >
                <v-btn small value="html">Html</v-btn>
                <v-btn small value="text">Text</v-btn>
                <v-btn small value="xml">Xml</v-btn>
                <v-btn small value="json">Json</v-btn>
              </v-btn-toggle>
              <!-- Setting v-model dynamically based on displayedFormat was unsuccessful
                   so if/if-else/else block was used out of necessity -->
              <prism-editor
                ref="e_html"
                class="mb-4 editor"
                :style="{ height: '400px' }"
                v-if="displayedFormat == 'html'"
                v-model="spot.html"
                @input="handleFormatInputEvent(spot.html, spot.templateHtml)"
                @click="
                  spot.html = !spot.html && spot.templateHtml ? spot.templateHtml : spot.html;
                  onEditor('click', 'html');
                "
                @blur="onEditor('blur', 'html')"
                lang="velocity"
                :placeholder="spot.templateHtml"
              ></prism-editor>
              <prism-editor
                ref="e_text"
                class="mb-4 editor"
                :style="{ height: '400px' }"
                v-else-if="displayedFormat == 'text'"
                v-model="spot.text"
                @input="handleFormatInputEvent(spot.text, spot.templateText)"
                @click="
                  spot.text = !spot.text && spot.templateText ? spot.templateText : spot.text;
                  onEditor('click', 'text');
                "
                @blur="onEditor('blur', 'text')"
                lang="velocity"
                :placeholder="spot.templateText"
              ></prism-editor>
              <prism-editor
                ref="e_xml"
                class="mb-4 editor"
                :style="{ height: '400px' }"
                v-else-if="displayedFormat == 'xml'"
                v-model="spot.xml"
                @input="handleFormatInputEvent(spot.xml, spot.templateXml)"
                @click="
                  spot.xml = !spot.xml && spot.templateXml ? spot.templateXml : spot.xml;
                  onEditor('click', 'xml');
                "
                @blur="onEditor('blur', 'xml')"
                lang="velocity"
                :placeholder="spot.templateXml"
              ></prism-editor>
              <prism-editor
                ref="e_json"
                class="mb-4 editor"
                :style="{ height: '400px' }"
                v-else-if="displayedFormat == 'json'"
                v-model="spot.json"
                @input="
                  handleFormatInputEvent(spot.json, spot.templateJson);
                  onEditor('input', 'json');
                "
                @click="
                  spot.json = !spot.json && spot.templateJson ? spot.templateJson : spot.json;
                  onEditor('click', 'json');
                "
                @blur="onEditor('blur', 'json')"
                lang="velocity"
                :placeholder="spot.templateJson"
              ></prism-editor>
            </template>
            <SpotEditActivity :spot="spot" ref="spotActivity" />
            <v-card-actions>
              <v-btn color="primary" :to="{ name: 'Spot Editor' }">
                Back to list
              </v-btn>
              <v-spacer></v-spacer>
              <app-spot-preview-dialog></app-spot-preview-dialog>
              <v-spacer></v-spacer>
              <v-btn
                :loading="saving"
                :disabled="!spotFormIsValid || saving || pageLoading"
                @click.prevent="saveSpot"
                color="success"
              >
                <v-icon>mdi-content-save</v-icon>
                Save
              </v-btn>
              <v-btn
                :disabled="!spotExists"
                :loading="deleteLoading"
                @click.prevent="deleteSpot"
                color="error"
                v-if="spot.type != `template-default` && !nonDeletableSpots.includes(spot.spot)"
              >
                <v-icon>mdi-delete</v-icon>Delete
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-dialog v-model="updateDialog.active" max-width="800">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="py-0">
          <v-text-field
            ref="reason"
            @keydown.enter="!updateDialog.reason || updateSpot()"
            @keydown.esc="updateDialog.active = false"
            hide-details="auto"
            outlined
            label="Reason for update"
            v-model="updateDialog.reason"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn plain @click="updateDialog.active = false">Cancel</v-btn>
          <v-btn plain color="primary" :disabled="!updateDialog.reason" @click="updateSpot"
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import PrismEditor from "@/components/util/PrismEditor";
import BackfillPoolsTable from "@/components/medusa/BackfillPoolsTable";
import SpotPreviewDialog from "@/components/medusa/SpotPreviewDialog";
import SpotEditActivity from "@/components/medusa/SpotEditActivity";
import { mapState, mapMutations } from "vuex";
import axios from "axios";
export default {
  props: ["spotId"],
  components: {
    PrismEditor,
    appBackfillPoolsTable: BackfillPoolsTable,
    appSpotPreviewDialog: SpotPreviewDialog,
    SpotEditActivity
  },
  data() {
    return {
      updateDialog: {
        active: false,
        reason: ""
      },
      spotNameLoading: false,
      displayedFormat: null,
      deleteLoading: false,
      templatePlaceholderLoading: false,
      spot_groups: [],
      template_spots: [],
      button_toggle: undefined,
      spotFormIsValid: true,
      saving: false,
      spotNameErrors: [],
      initalSpotName: null,
      initialSpotType: null,
      spotNameRequest: undefined,
      unsavedChanges: false,
      nonDeletableSpots: ["proxy", "monitor"],
      rules: {
        maxChars: (value, max) =>
          (value || "").length <= max || "Must be " + max + " or fewer characters",
        noSpaces: (value) => (value || "").indexOf(" ") === -1 || "Cannot contain spaces",
        /**
         * First checks if spot is still loading to prevent error message while spot data hasn't yet been loaded
         */
        required: (value) => this.spotLoading || !!value || "Required"
      }
    };
  },
  beforeDestroy() {
    this.setIsEditing(false);
  },
  created() {
    this.setIsEditing(false);
  },
  computed: mapState({
    isAdmin() {
      return this.$store.state.auth.user.rolename == "admin";
    },
    isTemplateSpot() {
      // true if spot type is template or template-default
      return this.initialSpotType != null && this.initialSpotType.startsWith("template");
    },
    poolLoading: (state) => {
      return state.backfillPools.loading;
    },
    pageLoading() {
      return (
        (this.spot.type == "spot" && this.spot.enable_backfill == `1` && this.poolLoading) ||
        this.spotLoading ||
        this.spotNameLoading
      );
    },
    pools: (state) => {
      return state.backfillPools.pools;
    },
    spot(state) {
      return state.spot.spot;
    },
    spotExists() {
      return this.spotId != "create";
    },
    spotLoading(state) {
      return state.spot.spotLoading;
    }
  }),
  // Lifecycle hook
  mounted() {
    this.resetState();
    if (this.spotExists) {
      this.populateExistingFormFromApi();
    } else {
      this.populateNewFormFromApi();
    }
  },
  watch: {
    "spot.template_spot_id": function(newVal, oldVal) {
      if (newVal != oldVal) {
        if (newVal != null) {
          this.templatePlaceholderLoading = true;
          this.axios
            .get("/medusa/spot/spotById", {
              params: {
                spotId: newVal
              }
            })
            .then((res) => {
              this.spot.templateXml = res.data.xml;
              this.spot.templateHtml = res.data.html;
              this.spot.templateJson = res.data.json;
              this.spot.templateText = res.data.text;
            })
            .finally(() => {
              this.templatePlaceholderLoading = false;
            });
        } else {
          this.spot.templateXml = null;
          this.spot.templateHtml = null;
          this.spot.templateJson = null;
          this.spot.templateText = null;
        }
      }
    }
  },
  methods: {
    onEditor(type, spot) {
      this.$nextTick(() => {
        if (this.$refs[`e_${spot}`]) {
          let height = "auto";
          if (!this.spot[spot]) {
            if (type == "blur" || type == "input") {
              height = "390px";
            }
          }
          this.$refs[`e_${spot}`].$el.querySelector(".prism-editor__container").style =
            "height: " + height;
        }
      });
    },
    ...mapMutations({
      setPreviewParamDialog: "spot/setPreviewParamDialog",
      setSpot: "spot/setSpot",
      setSpotLoading: "spot/setSpotLoading",
      setIsEditing: "backfillPools/setIsEditing",
      resetState: "spot/resetState"
    }),
    // Populate form for existing spots
    async populateExistingFormFromApi() {
      this.setSpotLoading(true);
      // Load all spot data together, and wait for all requests before
      // stopping loading animation
      Promise.all([
        this.axios.get("/medusa/spot/spotById", {
          params: {
            spotId: this.spotId
          }
        }),
        this.axios.get("medusa/spot/spotGroups"),
        this.axios.get("medusa/spot/templateSpots")
      ]).then(([spotRes, groupsRes, templateRes]) => {
        this.setSpotLoading(false);
        // If the spot doesn't exist in the DB
        if (spotRes.data == null) {
          // Display error message
          this.$store.commit("sendAlert", {
            msg: "Spot not found",
            color: "error"
          });
          // Route back to spot listing
          this.$router.push({ name: "Spot Editor" });
        }
        this.setSpot(spotRes.data);
        this.initalSpotName = spotRes.data.spot;
        this.initialSpotType = spotRes.data.type;
        // Map response to array of objects with 'text' and 'value' fields
        // Vuetify automatically will assign the item.text and item.value, rather
        // than having to do it via slots
        this.spot_groups = groupsRes.data.map((spot_group) => ({
          text: spot_group["group_name"],
          value: spot_group["spot_group_id"]
        }));
        this.template_spots = templateRes.data.map(function(e) {
          return { text: e["spot"], value: e["spot_id"] };
        });
        // Initially select format button of first non-null format
        if (this.spot.html) {
          this.displayedFormat = "html";
        } else if (this.spot.text) {
          this.displayedFormat = "text";
        } else if (this.spot.xml) {
          this.displayedFormat = "xml";
        } else if (this.spot.json) {
          this.displayedFormat = "json";
        }
      });
    },
    // Populate form for new spots
    async populateNewFormFromApi() {
      this.setSpotLoading(true);
      // Load all spot data together, and wait for all requests before
      // stopping loading animation
      Promise.all([
        this.axios.get("medusa/spot/spotGroups"),
        this.axios.get("medusa/spot/templateSpots")
      ]).then(([groupsRes, templateRes]) => {
        this.setSpotLoading(false);
        // Map response to array of objects with 'text' and 'value' fields
        // Vuetify automatically will assign the item.text and item.value, rather
        // than having to do it via slots
        this.spot_groups = groupsRes.data.map(function(e) {
          return { text: e["group_name"], value: e["spot_group_id"] };
        });
        this.template_spots = templateRes.data.map(function(e) {
          return { text: e["spot"], value: e["spot_id"] };
        });
      });
    },
    // Returns a promise which contains the deletion warning message
    buildTemplateDeleteMsg() {
      return new Promise((resolve) => {
        // It takes a second if spot is a template, so trigger loading symbol
        this.deleteLoading = true;
        let confirmationMsg = "Are you sure you want to delete " + this.spot.spot + "?";
        // Get the default template spot
        // If deleting a template spot, warn users of spots that will be impacted
        if (this.spot.type == "template") {
          // Need both api request responses, so must wait for both
          Promise.all([
            this.axios.get("/medusa/spot/defaultTemplateSpot"),
            // Get the spots using the current template spot
            this.axios.get("/medusa/spot/spotsByTemplateId", {
              params: {
                templateSpotId: this.spotId
              }
            })
          ])
            .then(([defaultRes, spotsRes]) => {
              let defaultTemplateSpot = defaultRes.data;
              let affectedSpots = spotsRes.data;
              let affectedSpotsStr = affectedSpots
                ? affectedSpots.map((spot) => spot.spot).join(", ")
                : "";
              confirmationMsg +=
                "\nThe following spots will now use default template (" +
                defaultTemplateSpot.spot +
                "): " +
                affectedSpotsStr;
              // return confimration message containing secondary impacts of deletion
            })
            .finally(() => {
              resolve(confirmationMsg);
            });
        } else {
          // return delete confirmation msg
          resolve(confirmationMsg);
        }
      });
    },
    async deleteSpot() {
      // waits for the confirmation message to be built before continuing
      let confirmationMsg = await this.buildTemplateDeleteMsg();
      // End loading symbol
      this.deleteLoading = false;
      this.$root
        .$confirm("Delete", confirmationMsg, {
          color: "red",
          width: 400
        })
        .then((confirm) => {
          if (confirm) {
            this.deleteLoading = true;
            this.axios
              .delete("/medusa/spot/deleteSpot", {
                data: {
                  spotId: this.spotId,
                  pools: this.spot.type == `spot` ? this.pools : null,
                  type: this.spot.type,
                  userName: this.$store.state.auth.user.username
                }
              })
              .then(() => {
                this.$emit("updateSpotListing");
                // Route back to spot list after deletion
                this.$router.push({ name: "Spot Editor" });
                // Display success alert
                this.$store.commit("sendAlert", {
                  msg: "Spot deleted",
                  color: "success"
                });
              })
              .finally(() => {
                this.deleteLoading = false;
              });
          }
        });
    },
    saveSpot() {
      if (this.spotExists) {
        this.onUpdateSpot();
      } else {
        this.insertSpot();
        this.setIsEditing(false);
      }
    },
    insertSpot() {
      this.saving = true;
      if (this.spot.template_spot_id) {
        this.spot.model = "";
      }
      //Submit Data
      this.axios
        .post("/medusa/spot/insertSpot", {
          spot: this.spot,
          pools: this.spot.type == `spot` ? this.pools : null
        })
        .then((insertRes) => {
          // Reload page with spotId
          this.$router.push({ name: "Spot Editor", params: { spotId: insertRes.data } });
          // Display success message
          this.$store.commit("sendAlert", {
            msg: "Spot Saved",
            color: "success"
          });
          // Update listing page data
          this.$emit("updateSpotListing");
        })
        .finally(() => {
          this.saving = false;
        });
    },
    onUpdateSpot() {
      this.updateDialog.active = true;
      this.updateDialog.reason = "";

      this.$nextTick(() => {
        const theElement = this.$refs.reason.$el;
        const input = theElement.querySelector(
          "input:not([type=hidden]),textarea:not([type=hidden])"
        );
        if (input) {
          setTimeout(() => {
            input.focus();
          }, 0);
        }
      });
    },
    updateSpot() {
      this.saving = true;
      this.updateDialog.active = false;
      // Clear model if template has been set (inherit instead)
      if (this.spot.template_spot_id) {
        this.spot.model = "";
      }
      // Clear display fields' values if equal to template's value for that field (inherit instead)
      if (this.spot.html == this.spot.templateHtml) {
        this.spot.html = null;
      }
      if (this.spot.text == this.spot.templateText) {
        this.spot.text = null;
      }
      if (this.spot.json == this.spot.templateJson) {
        this.spot.json = null;
      }
      if (this.spot.xml == this.spot.templateXml) {
        this.spot.xml = null;
      }

      ["html", "text", "xml", "json"].forEach((e) => this.onEditor("blur", e));

      //Submit Data
      this.axios
        .put("/medusa/spot/updateSpot", {
          spot: this.spot,
          pools: this.spot.type == `spot` ? this.pools : null,
          userName: this.$store.state.auth.user.username,
          reason: this.updateDialog.reason
        })
        .then(() => {
          // Display success message
          this.$store.commit("sendAlert", {
            msg: "Spot Saved",
            color: "success"
          });
          // Add keysSupplement if spot is a template, so all spots using the template are also purged
          let keysSupplement = this.isTemplateSpot ? "template_spot_id:" + this.spot.spot_id : null;
          // Purge spot cache
          this.$store.dispatch("medusaCampaigns/purgeMedusaCache", {
            cacheName: "spotCache",
            keys: this.spot.spot,
            keysSupplement: keysSupplement
          });
          // Purge spot backfillPools cache for this spot
          this.$store.dispatch("medusaCampaigns/purgeMedusaCache", {
            cacheName: "spotBackfillPoolsCache",
            keys: this.spot.spot,
            keysSupplement: null
          });
          // Update listing page data
          this.$emit("updateSpotListing");
          this.$refs.spotActivity.load();
        })
        .finally(() => {
          this.saving = false;
        });
      this.setIsEditing(false);
    },
    // Function checks DB to ensure spot name does not already exist.
    // Could not be in traditional rules due to axios request
    validateUniqueSpotName() {
      // Skip check if spot name matches initial spot name
      if (this.initalSpotName == this.spot.spot) return;
      this.setIsEditing(true);
      // Create cancel token factory
      const axiosSource = axios.CancelToken.source();
      // Cancel previous request, if one is pending
      if (this.spotNameRequest) this.spotNameRequest.cancel();
      // Create cancel token
      this.spotNameRequest = { cancel: axiosSource.cancel };
      this.spotNameLoading = true;
      this.axios
        .get("/medusa/spot/spotByName", {
          params: {
            spotName: this.spot.spot
          },
          cancelToken: axiosSource.token
        })
        .then((res) => {
          this.spotNameErrors = res.data != null ? [this.spot.spot + " already exists!"] : [];
        })
        .catch((err) => {
          // Must catch this exception to prevent an error message spam in the console
          if (axios.isCancel(err)) {
            // request cancelled, do nothing
          }
        })
        .finally(() => {
          this.spotNameLoading = false;
        });
    },
    // Sets isEditing to true if the new value does not match
    handleFormatInputEvent(newValue, templateValue) {
      if (newValue != templateValue) {
        this.setIsEditing(true);
      }
    }
  }
};
</script>

<style>
.editor .prism-editor__container {
  height: 390px;
}
</style>
