<template>
  <v-dialog scrollable :value="value" @keydown.esc="closeForm" @input="closeForm" max-width="800px">
    <v-card :loading="loading">
      <v-toolbar short flat>
        <v-btn icon @click="closeForm">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Change history for {{ spot.spot }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="px-0">
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th style="width:180px">On</th>
                <th>Reason</th>
                <th style="width:200px">Updated Fields</th>
                <th style="width:150px">By</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in items" :key="i">
                <td class="caption">
                  {{ item.modified_date }}
                </td>
                <td>
                  {{ item.reason }}
                </td>
                <td v-html="item.updated_fields"></td>
                <td>
                  {{ item.username }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["spot", "value"],
  data() {
    return {
      loading: false,
      items: []
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        if (v) this.load();
      }
    }
  },
  methods: {
    closeForm() {
      this.$emit("input", false);
    },
    load() {
      if (this.spot?.spot_id) {
        this.loading = true;
        this.$axios
          .get("/medusa/spot/getLog", {
            params: {
              spotId: this.spot.spot_id
            }
          })
          .then((res) => {
            this.items = res.data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  }
};
</script>

<style scoped>
td {
  vertical-align: text-top;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
</style>
